<template>
	<div style="background: #fff;">
	<!-- <div class="xieyi-box">
		{{loginText}}
	</div> -->
	<el-dialog class="des-modal" :visible.sync="show" :width="widthNum" :show-close="false" title="提示" left>
		<div class="text-c">
			<img src="../../assets/serch_loading.gif" class="img" style="width: 60px;position:relative;top:4px;" />
			<p class="ft16 fn mt20 pb20">{{loginText}}</p>
		</div>
	</el-dialog>
   </div>
</template>
<script>
	
	import { getAccountInfo, setAccountInfo } from '@/utils/localdatas';
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var accountApi=require('@/api/account.js');
	var that;
	
	export default {
		components: {},
		data() {
			return {
				returnurl:'',
				code:'',
				loginText:'正在登录中...',
				widthNum:100,
				show:true
			}
		},
	    created() {
	    	that = this;
			var query = that.$route.query;
			console.log(query);
			
	    	that.returnurl =decodeURIComponent(query.returnurl);
			that.code =query.code;
			console.log(getAccountInfo());
			// return;
			if(!yqfCommon.isNullOrEmpty(that.code)){
				GetLoginByUserCode();
			}
	    },
		mounted() {
			 console.log('当前地址：'+this.$route.path); 
			$(window).scrollTop("0");
		}
	}
	//根据账号登录
	function GetLoginByUserCode(){
		var parm={
			code:that.code
		};
		accountApi.GetLoginByUserCode(parm,function(result){
			console.log(result);
			if(result.code==0){
				setAccountInfo(result.data.accountinfo);
				if(that.returnurl.indexOf('http')!=-1){
					window.location.href=that.returnurl;
				}else{
					that.$router.push({ path: that.returnurl, query: { islongin: true } });				
				}
			}
			else{
				that.loginText=result.msg;
			}
		})
	}
</script>

<style lang="scss">
	
</style>
