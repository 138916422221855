/*账号接口*/
import {postAjaxRequest} from '@/utils/request'
import {yqfApiurl,yqfComApiurl} from '@/utils/webconfig'

//登录
export function accountLoginByOrganizaType(parm, callback) {
	console.log('accountLoginByOrganizaType');
	postAjaxRequest('/AccountAPI/AccountLoginByOrganizaType', parm, function(result) {
		callback(result);
	})	
}
//获取验证码
export function SendBandSMSCode(parm, callback) {
	console.log('SendBandSMSCode');
	postAjaxRequest('/WXMiniAppApi/SendBandSMSCode', parm, function(result) {
		callback(result);
	})	
}
//根据账号登录
export function GetLoginByUserCode(parm, callback) {
	console.log('GetLoginByUserCode');
	postAjaxRequest('/AccountAPI/GetLoginByUserCode', parm, function(result) {
		callback(result);
	})	
}


